import React, { ReactNode } from 'react'
import { CContainer, CFooter } from '@coreui/react'
import SettingsSidebar from '../components/Layout/SettingsSidebar'
import Header from '../components/Layout/Header'

const MainLayout : React.FC<{ children: ReactNode }> = ({ children }) => (
    <>
        <SettingsSidebar />
        <Header />
        <CContainer fluid>
            {children}
        </CContainer>
        <CFooter style={{ marginTop: '15px', textAlign: 'right' }}>
            PIP Helper - created by Overkiz
        </CFooter>
    </>
)

export default MainLayout
