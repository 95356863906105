import React from 'react'
import { CButton, CNavbarText, CNavTitle } from '@coreui/react'
import { useDispatch } from 'react-redux'
import { CIcon } from '@coreui/icons-react'
import { cilCasino } from '@coreui/icons'
import { shuffleTeams } from '../../../stores/teams/Action'

const SettingsTeams : React.FC<unknown> = () => {
    const dispatch = useDispatch()

    const handleShuffleTeams = ():void => {
        dispatch(shuffleTeams())
    }

    return (
        <>
            <CNavTitle>Teams</CNavTitle>
            <CNavbarText className="d-grid gap-2">
                <CButton color="primary" size="sm" onClick={():void => handleShuffleTeams()}>Shuffle Teams <CIcon icon={cilCasino}/></CButton>
            </CNavbarText>
        </>
    )
}

export default SettingsTeams
