import React from 'react'
import { CWidgetStatsB } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import { useSelector } from 'react-redux'
import { getCurrentProductIncrement, getDaysRemaining, getNextProductIncrement } from '../../stores/productIncrements/Selector'

const DaysRemaining: React.FC = () => {
    const currentProgramIncrement = useSelector(getCurrentProductIncrement)
    const nextProgramIncrement = useSelector(getNextProductIncrement)
    const days = useSelector(getDaysRemaining)

    const progress = days.total > 0 ? (days.passed / days.total) * 100 : 0

    return (
        <CWidgetStatsB
            className="mb-4 bg-light"
            progress={{ color: 'primary', value: progress }}
            value={`${days.remaining} days remaining`}
            title={`Before PI ${nextProgramIncrement.name}`}
            text={`PI ${currentProgramIncrement.name} : ${new Date(currentProgramIncrement.start).toLocaleDateString()} - ${new Date(currentProgramIncrement.end).toLocaleDateString()}`}
        />
    )
}

export default DaysRemaining
