import React from 'react'
import { CProgress, CProgressStacked, CTableDataCell, CTableHeaderCell, CTableRow } from '@coreui/react'

const ProgressRow : React.FC<{ label: string, total: number, done: number, inProgress: number }> = ({ label, total, done, inProgress }) => {
    const doneProgress = total ? (done / total) * 100 : 0
    const doneAndOnGoingProgress = total ? ((inProgress + done) / total) * 100 : 0

    return (
        <CTableRow>
            <CTableHeaderCell>{label}</CTableHeaderCell>
            <CTableDataCell>{total}</CTableDataCell>
            <CTableDataCell>{done}</CTableDataCell>
            <CTableDataCell>{inProgress}</CTableDataCell>
            <CTableDataCell>{total - inProgress - done}</CTableDataCell>
            <CTableDataCell>{inProgress > 0 ? `~ ${Math.round(doneProgress)}-${Math.round(doneAndOnGoingProgress)} %` : `${Math.round(doneProgress)} %`}</CTableDataCell>
            <CTableDataCell className="w-25">
                <CProgressStacked>
                    <CProgress color="success" value={total ? (done / total) * 100 : 0} />
                    <CProgress color="info" variant="striped" animated value={total ? (inProgress / total) * 100 : 0} />
                </CProgressStacked>
            </CTableDataCell>
        </CTableRow>
    )
}

export default ProgressRow
