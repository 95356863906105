import React from 'react'
import { useRouteError } from 'react-router'

const ErrorPage : React.FC = () => {
    const error : { statusText?:string, message?:string } = useRouteError() as { statusText?:string, message?:string }
    console.error(error)

    return (
        <div id="error-page">
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    )
}

export default ErrorPage
