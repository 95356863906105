export interface IssueQuery {
    query: string
    autoRefresh: boolean
}

class Refresher {
    private lastQuery: IssueQuery

    private refreshTimeout: NodeJS.Timeout

    private refreshInterval: number

    constructor () {
        const storedInterval = localStorage.getItem('settings.refresh-interval')
        this.refreshInterval = storedInterval ? parseInt(storedInterval, 10) : 15
    }

    public registerQuery (query: { query: string, autoRefresh: boolean }) : void {
        this.lastQuery = query

        if (this.refreshTimeout)
            clearTimeout(this.refreshTimeout)
    }

    public registerAutoRefresh (callback: (lastQuery:IssueQuery) => void) : void {
        this.refreshTimeout = setTimeout(() => {
            callback(this.lastQuery)
        }, this.refreshInterval * 1000)
    }

    public setInterval (interval:number) : void {
        this.refreshInterval = interval
        localStorage.setItem('settings.refresh-interval', String(interval))
    }

    public getInterval () : number {
        return this.refreshInterval
    }

    public getLastQuery () : { query: string, autoRefresh: boolean } {
        return this.lastQuery
    }
}

export default new Refresher()
