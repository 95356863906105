import React, { ReactNode, useState } from 'react'
import { CCard, CCardBody, CCardHeader, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import { useSelector } from 'react-redux'
import Team from '../../models/Team'
import Loader from './Loader'
import { getTeams } from '../../stores/teams/Selector'

const TeamTabs: React.FC<{ content: (team:Team) => ReactNode }> = ({ content }) => {
    const teams = useSelector(getTeams)

    const [activeKey, setActiveKey] = useState(teams[0].teamId)

    return (
        <>
            <CCard>
                <CCardHeader>
                    <CNav variant="tabs" className="card-header-tabs">
                        {teams.map((team) => (
                            <CNavItem key={`team-tab-${team.teamId}`}>
                                <CNavLink href="#!" active={activeKey === team.teamId} onClick={():void => setActiveKey(team.teamId)}>
                                    {team.name}
                                </CNavLink>
                            </CNavItem>
                        ))}
                    </CNav>
                </CCardHeader>
                <CCardBody>
                    <CTabContent>
                        <Loader>
                            {teams.map((team) => (
                                <CTabPane key={`team-panel-${team.teamId}`} role="tabpanel" aria-labelledby={team.teamId} visible={activeKey === team.teamId}>
                                    {content(team)}
                                </CTabPane>
                            ))}
                        </Loader>
                    </CTabContent>
                </CCardBody>
            </CCard>
        </>
    )
}

export default TeamTabs
