import React from 'react'
import { useSelector } from 'react-redux'
import { CChart } from '@coreui/react-chartjs'
import { getStyle } from '@coreui/utils/dist/cjs'
import { getIssuesByCommitment } from '../../stores/issues/Selector'
import Utils from '../../utils/Utils'
import Loader from '../Shared/Loader'

const CommitmentPie : React.FC = () => {
    const issuesByCommitment = useSelector(getIssuesByCommitment)

    const committedSum = issuesByCommitment.committed.reduce((acc:number, issue) => acc + Utils.normalizeSize(issue), 0)
    const notCommittedSum = issuesByCommitment.notCommitted.reduce((acc:number, issue) => acc + Utils.normalizeSize(issue), 0)

    return (
        <>
            <Loader>
                <CChart
                    type="doughnut"
                    data={{
                        labels: ['Committed', 'Not Committed'],
                        datasets: [
                            {
                                backgroundColor: ['#41B883', '#E46651'],
                                data: [committedSum, notCommittedSum],
                            },
                        ],
                    }}
                    options={{
                        plugins: {
                            legend: {
                                labels: {
                                    color: getStyle('--cui-body-color'),
                                },
                            },
                        },
                    }}
                />
            </Loader>
        </>
    )
}

export default CommitmentPie
