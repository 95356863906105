import { createSelector } from '@reduxjs/toolkit'
import State from '../State'
import { ProductIncrementState } from './Reducer'
import ProductIncrement from '../../models/ProductIncrement'

export const getProductIncrements = createSelector(
    (state: State) => state.productIncrements,
    (pis: ProductIncrementState) => (
        [...pis.productIncrements].sort((a, b) => a.start - b.start)
    ),
)

export const getCurrentProductIncrement = createSelector(
    [getProductIncrements],
    (productIncrements: ProductIncrement[]) => {
        const currentDate = new Date().setUTCHours(0, 0, 0, 0)
        for (let i = 0; i < productIncrements.length; i++) {
            if (productIncrements[i].start <= currentDate && productIncrements[i].end >= currentDate) {
                return productIncrements[i]
            }
        }

        throw new Error('Cannot found current product increment.')
    },
)

export const getNextProductIncrement = createSelector(
    [getProductIncrements],
    (productIncrements: ProductIncrement[]) => {
        const currentDate = new Date().setUTCHours(0, 0, 0, 0)
        for (let i = 0; i < productIncrements.length; i++) {
            if (productIncrements[i].start > currentDate) {
                return productIncrements[i]
            }
        }

        throw new Error('Cannot found next product increment.')
    },
)

export const getNextProductIncrements = createSelector(
    [getProductIncrements],
    (productIncrements: ProductIncrement[]) => {
        const currentDate = new Date().setUTCHours(0, 0, 0, 0)
        return productIncrements.filter((pi) => pi.start > currentDate)
    },
)

export const getDaysRemaining = createSelector(
    [getCurrentProductIncrement],
    (currentProgramIncrement) => {
        const totalDays = Math.ceil((currentProgramIncrement.end - currentProgramIncrement.start) / (1000 * 3600 * 24))
        const today = new Date()
        const daysPassed = Math.ceil((today.getTime() - currentProgramIncrement.start) / (1000 * 3600 * 24))
        const daysRemaining = Math.max(totalDays - daysPassed, 0)
        return { passed: daysPassed, total: totalDays, remaining: daysRemaining }
    },
)
