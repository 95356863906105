import { configureStore } from '@reduxjs/toolkit'
import { issuesReducer } from './issues/Reducer'
import { teamReducer } from './teams/Reducer'
import { credentialReducer } from './credentials/Reducer'
import { productIncrementReducer } from './productIncrements/Reducer'
import { navigationReducer } from './navigation/Reducer'

export default configureStore({
    reducer: {
        issues: issuesReducer,
        teams: teamReducer,
        credentials: credentialReducer,
        productIncrements: productIncrementReducer,
        navigation: navigationReducer,
    },
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware({
            serializableCheck: false,
        })
    ),
})
