import axios, { Axios, AxiosResponse, Method, CreateAxiosDefaults } from 'axios'
import Issue from '../models/jira/Issue'
import Worklog from '../models/jira/Worklog'

class JiraClient {
    private axiosClient: Axios

    private oauth: { user?: string, token?: string } = {}

    constructor (baseOptions: CreateAxiosDefaults) {
        this.axiosClient = axios.create(baseOptions)
    }

    public setAuthorization (user: string, token: string): void {
        this.oauth = {
            user,
            token,
        }
    }

    public resetAuthorization (): void {
        this.oauth = {}
    }

    private async fetch<T = unknown>(method: Method, path: string, data?: unknown): Promise<T> {
        const response = await this.axiosClient.request<unknown, AxiosResponse<T>>({
            url: path,
            method,
            data,
            headers: {
                Authorization: this.oauth.user && this.oauth.token ? `Basic ${btoa(`${this.oauth.user}:${this.oauth.token}`)}` : '',
            },
        })

        if (response.status >= 400) {
            console.error(`Error ${response.status} on JIRA Request : ${response.statusText}`)
            console.error(response)
            throw new Error(`Error ${response.status} on JIRA Request : ${response.statusText}`)
        }

        return response.data
    }

    private async getRequest<T = unknown>(path: string): Promise<T> {
        return this.fetch<T>('GET', path)
    }

    private async postRequest<T = unknown>(path: string, data: unknown): Promise<T> {
        return this.fetch<T>('POST', path, data)
    }

    async searchIssues (query: string): Promise<Issue[]> {
        let page = 0
        let requestIssues: { issues: Issue[] } = { issues: [] }
        let allIssues: Issue[] = []

        do {
            const qs = new URLSearchParams({ maxResults: '50', startAt: `${page * 50}`, jql: query }).toString()
            // eslint-disable-next-line no-await-in-loop
            requestIssues = await this.getRequest<{ issues: Issue[] }>(`/rest/api/3/search?${qs}`)
            allIssues = [...allIssues, ...requestIssues.issues]
            page += 1
        } while (requestIssues.issues.length === 50)

        return allIssues
    }

    async getIssue (issueKey: string): Promise<Issue> {
        return this.getRequest<Issue>(`/rest/api/3/issue/${issueKey}`)
    }

    async getIssueWorklog (issueKey: string): Promise<Worklog[]> {
        const payload = await this.getRequest<{ worklogs: Worklog[] }>(`/rest/api/3/issue/${issueKey}/worklog`)
        return payload.worklogs
    }

    async getChildren (issueKey: string): Promise<Issue[]> {
        return this.searchIssues(`parent = ${issueKey}`)
    }
}

export default new JiraClient({
    baseURL: '',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})
