import React, { useState } from 'react'
import { CButtonGroup, CFormCheck, CNavbarText, CNavTitle } from '@coreui/react'
import Refresher from '../../../services/Refresher'

const SettingsRefresher : React.FC<unknown> = () => {
    const [refreshTime, setRefreshTime] = useState(Refresher.getInterval())

    const refreshTimeValues = [5, 15, 30, 60]
    const handleChange = (value:number):void => {
        setRefreshTime(value)
        Refresher.setInterval(value)
    }

    return (
        <>
            <CNavTitle>Auto Refresh</CNavTitle>
            <CNavbarText className="d-grid gap-2">
                <CButtonGroup size="sm" role="group">
                    {refreshTimeValues.map((time) => (
                        <CFormCheck
                            type="radio"
                            button={{ color: 'primary', variant: 'outline' }}
                            name={`auto-refresh-${time}`}
                            id={`auto-refresh-${time}`}
                            autoComplete="off"
                            label={`${time} s`}
                            onClick={():void => handleChange(time)}
                            defaultChecked={time === refreshTime}
                            key={`auto-refresh-${time}`}
                        />
                    ))}
                </CButtonGroup>
            </CNavbarText>
        </>
    )
}

export default SettingsRefresher
