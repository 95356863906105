import Issue from '../models/jira/Issue'
import { TypedEffort, GroupedMap } from '../models/Types'
import { CustomField } from '../models/jira/CustomField'

export default class Utils {
    /**
     * Convertit une taille en S
     * @param {string} size - La taille à convertir (XS, S, M, L, XL)
     * @returns {number} Le nombre équivalent en S
     */
    public static convertSizeToS (size: string): number {
        switch (size) {
            case 'XS': return 0.5
            case 'S': return 1
            case 'M': return 3
            case 'L': return 5
            case 'XL': return 7
            default: return 0
        }
    }

    public static normalizeSize (issue: Issue): number {
        const sizes = issue.fields[CustomField.SIZE]?.value || []
        const sizeArray: string[] = Array.isArray(sizes) ? sizes : [sizes]
        return sizeArray.reduce((sum:number, size:string) => sum + this.convertSizeToS(size), 0)
    }

    public static getEffortByAccount (goodIssues: Issue[], badIssues: Issue[], accountExtractor: (account:string) => string = (account:string):string => account): GroupedMap<TypedEffort> {
        const result : GroupedMap<TypedEffort> = {}

        goodIssues.forEach((issue) => {
            const size = this.normalizeSize(issue)
            const accountValue = issue.fields[CustomField.ACCOUNT]?.value !== undefined ? accountExtractor(issue.fields[CustomField.ACCOUNT].value) : 'No Account Defined'

            if (!result[accountValue]) {
                result[accountValue] = {
                    good: 0,
                    bad: 0,
                    all: 0,
                }
            }

            result[accountValue].good += size
            result[accountValue].all += size
        })

        badIssues.forEach((issue) => {
            const size = this.normalizeSize(issue)
            const accountValue = issue.fields[CustomField.ACCOUNT]?.value !== undefined ? accountExtractor(issue.fields[CustomField.ACCOUNT].value) : 'No Account Defined'

            if (!result[accountValue]) {
                result[accountValue] = {
                    good: 0,
                    bad: 0,
                    all: 0,
                }
            }

            result[accountValue].bad += size
            result[accountValue].all += size
        })

        return result
    }

    public static isIssueReady (issue: Issue) : boolean {
        return issue.fields[CustomField.ACCOUNT]?.value !== undefined && issue.fields.status.name !== 'Backlog' && this.normalizeSize(issue) > 0
    }

    public static extractMainAccount (subAccount: string) : string {
        if (!subAccount) {
            return 'No Account Defined'
        }

        return subAccount.split('-')[0].trim()
    }
}
