import { createReducer } from '@reduxjs/toolkit'
import { closeSettingsSidebar, openSettingsSidebar } from './Action'

export type NavigationState = {
    settingsSidebar: boolean
}

export const navigationReducer = createReducer({ settingsSidebar: false }, (builder) => {
    builder
        .addCase(openSettingsSidebar, (state: NavigationState) => {
            state.settingsSidebar = true
        })
        .addCase(closeSettingsSidebar, (state: NavigationState) => {
            state.settingsSidebar = false
        })
})
