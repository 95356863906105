import React, { useState } from 'react'
import { CButtonGroup, CFormCheck, CNavbarText, CNavTitle } from '@coreui/react'
import { CIcon } from '@coreui/icons-react'
import { cilMoon, cilSun } from '@coreui/icons'

const SettingsDarkMode : React.FC<unknown> = () => {
    const storedTheme = localStorage.getItem('settings.dark-mode')
    const html = document.querySelector('html')

    if (storedTheme) {
        html?.setAttribute('data-coreui-theme', storedTheme)
    }

    const [theme, setTheme] = useState(storedTheme ?? 'light')

    const handleDarkMode = (selectedTheme:string):void => {
        setTheme(selectedTheme)
        localStorage.setItem('settings.dark-mode', selectedTheme)
        html?.setAttribute('data-coreui-theme', selectedTheme)
    }

    return (
        <>
            <CNavTitle>Dark Mode</CNavTitle>
            <CNavbarText className="d-grid gap-2">
                <CButtonGroup size="sm" role="group">
                    <CFormCheck
                        type="radio"
                        button={{ color: 'primary', variant: 'outline' }}
                        name='dark-mode-light'
                        id='dark-mode-light'
                        autoComplete="off"
                        label={<CIcon icon={cilSun} />}
                        onClick={():void => handleDarkMode('light')}
                        defaultChecked={theme === 'light'}
                    />
                    <CFormCheck
                        type="radio"
                        button={{ color: 'primary', variant: 'outline' }}
                        name='dark-mode-dark'
                        id='dark-mode-dark'
                        autoComplete="off"
                        label={<CIcon icon={cilMoon} />}
                        onClick={():void => handleDarkMode('dark')}
                        defaultChecked={theme === 'dark'}
                    />
                </CButtonGroup>
            </CNavbarText>
        </>
    )
}

export default SettingsDarkMode
