import { createSelector } from '@reduxjs/toolkit'
import State from '../State'
import { TeamsState } from './Reducer'

export const getTeams = createSelector(
    (state: State) => state.teams,
    (teamState: TeamsState) => teamState.items,
)

export const getBillableTeams = createSelector(
    [getTeams],
    (teams) => teams.filter((team) => team.billable === undefined || team.billable),
)
