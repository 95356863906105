import React from 'react'
import { useSelector } from 'react-redux'
import { CCol, CRow, CWidgetStatsB } from '@coreui/react'
import { getIssuesByTeam } from '../../stores/issues/Selector'
import Utils from '../../utils/Utils'
import { getTeams } from '../../stores/teams/Selector'
import { StatusCategory } from '../../models/jira/StatusCategory'
import { getDaysRemaining } from '../../stores/productIncrements/Selector'
import { CustomField } from '../../models/jira/CustomField'
import { IssueCommitment } from '../../models/jira/IssueCommitment'
import DaysRemaining from '../Shared/DaysRemaining'
import Loader from '../Shared/Loader'

const CurrentPIDashboard: React.FC = () => {
    const teams = useSelector(getTeams)
    const issues = useSelector(getIssuesByTeam)
    const days = useSelector(getDaysRemaining)
    const daysRatio = days.total > 0 ? (days.passed / days.total) * 100 : 100

    const getColor = (progress:number, total: number):string => {
        const completionRate = total > 0 ? (progress / total) * 100 : 0

        if (completionRate >= daysRatio)
            return 'success'

        if (completionRate >= daysRatio - 10)
            return 'info'

        if (completionRate >= daysRatio - 25)
            return 'warning'

        return 'danger'
    }

    const progresses = teams.map((team) => {
        const teamIssues = !issues[team.teamId] ? [] : issues[team.teamId].filter((i):boolean => i.fields[CustomField.COMMITMENT]?.id === IssueCommitment.COMMITTED)

        const total = teamIssues.reduce((acc:number, issue) => acc + Utils.normalizeSize(issue), 0)
        const done = teamIssues
            .filter((issue) => issue.fields.status.statusCategory.id === StatusCategory.DONE)
            .reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0)
        const inProgress = teamIssues
            .filter((issue) => issue.fields.status.statusCategory.id === StatusCategory.IN_PROGRESS)
            .reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0)

        return {
            team,
            points: {
                total,
                done,
                progress: inProgress,
            },
            features: {
                total: teamIssues.length,
                done: teamIssues.filter((issue) => issue.fields.status.statusCategory.id === StatusCategory.DONE).length,
                progress: teamIssues.filter((issue) => issue.fields.status.statusCategory.id === StatusCategory.IN_PROGRESS).length,
            },
            color: getColor(done, total),
        }
    })

    const globalPointTotal = progresses.reduce((acc, progress) => acc + progress.points.total, 0)
    const globalPointDone = progresses.reduce((acc, progress) => acc + progress.points.done, 0)
    const globalPointProgress = progresses.reduce((acc, progress) => acc + progress.points.progress, 0)
    const globalFeaturesTotal = progresses.reduce((acc, progress) => acc + progress.features.total, 0)
    const globalFeaturesDone = progresses.reduce((acc, progress) => acc + progress.features.done, 0)
    const globalFeaturesProgress = progresses.reduce((acc, progress) => acc + progress.features.progress, 0)

    return (
        <>
            <CRow>
                <CCol lg={3}>
                    <DaysRemaining/>
                </CCol>
                <Loader>
                    <CCol lg={6}>
                        <Loader>
                            <CWidgetStatsB
                                className={'mb-4'}
                                color={getColor(globalPointDone, globalPointTotal)}
                                inverse
                                progress={{ value: (globalPointDone / globalPointTotal) * 100 }}
                                title={`Done : ${Math.round((globalPointDone / globalPointTotal) * 100)} % - ${globalPointDone}/${globalPointTotal} points - ${globalFeaturesDone}/${globalFeaturesTotal} features`}
                                value='ISS Train'
                                text={`In progress : ${Math.round((globalPointProgress / globalPointTotal) * 100)} % - ${globalPointProgress}/${globalPointTotal} points - ${globalFeaturesProgress}/${globalFeaturesTotal} features`}
                                key={'current-pi-dashboard-global'}
                            />
                        </Loader>
                    </CCol>
                    {progresses.map((teamProgress) => (
                        <CCol lg={3}>
                            <CWidgetStatsB
                                className={'mb-4'}
                                color={teamProgress.color}
                                inverse
                                progress={{ value: (teamProgress.points.done / teamProgress.points.total) * 100 }}
                                title={`Done : ${Math.round((teamProgress.points.done / teamProgress.points.total) * 100)} % - ${teamProgress.points.done}/${teamProgress.points.total} points - ${teamProgress.features.done}/${teamProgress.features.total} features`}
                                value={teamProgress.team.name}
                                text={`In progress : ${Math.round((teamProgress.points.progress / teamProgress.points.total) * 100)} % - ${teamProgress.points.progress}/${teamProgress.points.total} points - ${teamProgress.points.progress}/${teamProgress.points.total} features`}
                                key={`current-pi-dashboard-${teamProgress.team.teamId}`}
                            />
                        </CCol>
                    ))}
                </Loader>
            </CRow>
        </>
    )
}

export default CurrentPIDashboard
