import React, { useState } from 'react'
import { CButton, CContainer, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CForm, CFormInput, CHeader, CHeaderBrand, CNavbar, CNavbarNav, CNavItem, CNavLink } from '@coreui/react'
import { generatePath, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { cilCog, cilSync } from '@coreui/icons'
import { CIcon } from '@coreui/icons-react'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import { openSettingsSidebar as openSettingsSidebarAction } from '../../stores/navigation/Action'
import State from '../../stores/State'
import { searchIssues } from '../../stores/issues/Action'
import Refresher from '../../services/Refresher'
import { getRefreshStatus } from '../../stores/issues/Selector'
import { RefreshStatus } from '../../models/RefreshStatus'
import { getNextProductIncrement, getNextProductIncrements } from '../../stores/productIncrements/Selector'

const Header : React.FC<unknown> = () => {
    const dispatch = useDispatch<ThunkDispatch<State, unknown, UnknownAction>>()
    const [searchIssue, setSearchIssue] = useState<string>()
    const refreshStatus = useSelector(getRefreshStatus)
    const nextProductIncrement = useSelector(getNextProductIncrement)
    const nextProductIncrements = useSelector(getNextProductIncrements)

    const navigate = useNavigate()

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>):void => {
        e.preventDefault()

        if (!searchIssue) {
            return
        }

        const result = searchIssue.match(/([a-zA-Z]+-[0-9]+)$/)

        if (!result) {
            return
        }

        navigate(generatePath('/issues/:issueKey', { issueKey: result[0] }))
    }

    const openSettingsSidebar = ():void => {
        dispatch(openSettingsSidebarAction())
    }

    const refreshLastQuery = ():void => {
        dispatch(searchIssues(Refresher.getLastQuery())).catch(() => {
            console.error('Failed to refresh issues')
        })
    }

    return (
        <CHeader position='sticky' style={{ marginBottom: '15px' }}>
            <CNavbar expand="lg">
                <CContainer fluid>
                    <CHeaderBrand onClick={():void => navigate('/')} className="cil-mouse font-monospace">PIP Helper</CHeaderBrand>
                    <CNavbarNav>
                        <CNavItem>
                            <CNavLink onClick={():void => navigate('/')} active>
                                Current PI
                            </CNavLink>
                        </CNavItem>
                        <CDropdown variant="btn-group">
                            <CButton as="a" onClick={():void => navigate(`/next/${encodeURIComponent(nextProductIncrement.tag)}`)}>Next PI</CButton>
                            <CDropdownToggle split />
                            <CDropdownMenu>
                                {nextProductIncrements.slice(0, 4).map((pi, index) => (
                                    <CDropdownItem key={`menu-next-pi-${index}`} onClick={():void => navigate(`/next/${encodeURIComponent(pi.tag)}`)}>{pi.name}</CDropdownItem>
                                ))}
                            </CDropdownMenu>
                        </CDropdown>
                    </CNavbarNav>
                </CContainer>
            </CNavbar>
            <CForm className="d-flex" onSubmit={handleSubmit}>
                <CFormInput type="search" className="me-2" placeholder="Search" onChange={(event):void => setSearchIssue(event.target.value)} />
                <CButton onClick={refreshLastQuery} disabled={refreshStatus === RefreshStatus.REFRESHING} color="primary" className="me-2">
                    {refreshStatus === RefreshStatus.REFRESHING ? (<span className="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></span>) : (<CIcon icon={cilSync}/>)}
                </CButton>
                <CButton onClick={openSettingsSidebar} variant="outline" color='secondary'><CIcon icon={cilCog}></CIcon></CButton>
            </CForm>
        </CHeader>
    )
}

export default Header
