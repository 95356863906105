import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { getIssuesCount } from '../../stores/issues/Selector'

const Loader: React.FC<{ children: ReactNode }> = ({ children }) => {
    const issueCount = useSelector(getIssuesCount)
    return issueCount === 0 ? (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    ) : children
}

export default Loader
