import React from 'react'
import { CChart } from '@coreui/react-chartjs'
import { Chart as ChartJS, BarElement, Title, Legend, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { GroupedMap, TypedEffort } from '../../models/Types'

ChartJS.register(BarElement, Title, Legend, ChartDataLabels, CategoryScale, LinearScale)

const AccountChart: React.FC<{ efforts: GroupedMap<TypedEffort>, goodLabel: string, goodColor?: string }> = ({ efforts, goodLabel, goodColor }) => {
    // Calculer la somme de tous les committed et all pour obtenir le total global
    const totalGlobal = Object.values(efforts).reduce((total, sizes) => total + sizes.all, 0)
    const totalGood = Object.values(efforts).reduce((total, sizes) => total + sizes.good, 0)

    // Trier les comptes par committed
    const sortedEntries = Object.entries(efforts).sort(([, a], [, b]) => b.all - a.all)
    const labels = sortedEntries.map(([account]) => account)
    const goodData = sortedEntries.map(([, { good }]) => good)
    const badData = sortedEntries.map(([, { bad }]) => bad)

    // Calculer les totaux par barre
    const barTotals = sortedEntries.map(([, { all }]) => all)

    // Trouver le maximum de barTotals pour définir la limite supérieure
    const maxBarTotal = Math.max(...barTotals)

    return (
        <div>
            <CChart
                type='bar'
                data={{
                    labels,
                    datasets: [
                        {
                            label: goodLabel,
                            data: goodData,
                            backgroundColor: goodColor ?? '#28A745', // Vert pour committed
                        },
                        {
                            label: 'Total',
                            data: badData,
                            backgroundColor: '#6C757D', // Gris pour la différence
                        },
                    ],
                }}
                options={{
                    plugins: {
                        datalabels: {
                            display: false, // Masquer les labels pour les autres datasets
                        },
                        tooltip: {
                            callbacks: {
                                label: (tooltipItem): string | undefined => {
                                    if (tooltipItem.datasetIndex === 0) {
                                        return `${goodLabel} : ${tooltipItem.formattedValue} (${Math.round((tooltipItem.raw as number / totalGood) * 100)}%)`
                                    }
                                    if (tooltipItem.datasetIndex === 1) {
                                        const total = (tooltipItem.raw as number) + ((tooltipItem.chart.data.datasets[0]?.data[tooltipItem.dataIndex] ?? 0) as number)
                                        return `Total : ${total} (${Math.round((total / totalGlobal) * 100)}%)`
                                    }
                                    return 'N/A'
                                },
                            },
                        },
                    },
                    responsive: true,
                    scales: {
                        x: {
                            stacked: true, // Empiler les barres sur l'axe x
                            beginAtZero: true,
                            ticks: {
                                font: {
                                    size: 14,
                                },
                            },
                        },
                        y: {
                            stacked: true, // Empiler les barres sur l'axe y
                            beginAtZero: true,
                            title: {
                                display: true,
                                text: 'Σ Job Size',
                                font: {
                                    size: 14,
                                },
                            },
                            // Définir les limites minimales et maximales de l'échelle y
                            min: 0,
                            max: maxBarTotal * 1.1, // Ajouter une marge de 10% au-dessus du maximum des données
                            ticks: {
                                stepSize: Math.ceil(maxBarTotal / 10), // Ajuster les pas des ticks pour plus de précision
                            },
                        },
                    },
                }}
            />
        </div>
    )
}

export default AccountChart
