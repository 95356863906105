import { createReducer } from '@reduxjs/toolkit'
import ProductIncrement from '../../models/ProductIncrement'
import { updateProductIncrements } from './Action'

export type ProductIncrementState = {
    productIncrements: ProductIncrement[]
}

const initialValue: ProductIncrement[] = [
    { name: 'Erable', tag: 'Erable', start: new Date('2024-07-23').getTime(), end: new Date('2024-09-23').getTime() },
    { name: 'Ginkgo', tag: 'Ginkgo', start: new Date('2024-09-24').getTime(), end: new Date('2024-11-25').getTime() },
    { name: 'Pin', tag: 'Pin', start: new Date('2024-11-26').getTime(), end: new Date('2025-01-27').getTime() },
    { name: 'Houx', tag: 'Houx', start: new Date('2025-01-28').getTime(), end: new Date('2025-03-31').getTime() },
    { name: 'Magnolia', tag: 'Magnolia', start: new Date('2025-04-01').getTime(), end: new Date('2025-06-02').getTime() },
    { name: 'Sakura', tag: 'Sakura 2025', start: new Date('2025-06-03').getTime(), end: new Date('2025-08-04').getTime() },
    { name: 'Erable', tag: 'Erable 2025', start: new Date('2025-08-05').getTime(), end: new Date('2025-10-06').getTime() },
    { name: 'Ginkgo', tag: 'Ginkgo 2025', start: new Date('2025-10-07').getTime(), end: new Date('2025-12-08').getTime() },
]

export const productIncrementReducer = createReducer({ productIncrements: initialValue }, (builder) => {
    builder
        .addCase(updateProductIncrements, (state: ProductIncrementState, { payload }) => {
            state.productIncrements = payload
        })
})
