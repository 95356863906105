import React from 'react'
import { CSidebar, CSidebarHeader, CSidebarNav } from '@coreui/react'
import { useDispatch, useSelector } from 'react-redux'
import { CIcon } from '@coreui/icons-react'
import { cilChevronRight } from '@coreui/icons'
import { isSettingsSidebarVisible } from '../../stores/navigation/Selector'
import { closeSettingsSidebar } from '../../stores/navigation/Action'
import SettingsDarkMode from './Settings/SettingsDarkMode'
import SettingsTeams from './Settings/SettingsTeams'
import SettingsRefresher from './Settings/SettingsRefresher'

const SettingsSidebar : React.FC<unknown> = () => {
    const dispatch = useDispatch()
    const visible = useSelector(isSettingsSidebarVisible)

    const close = ():void => {
        dispatch(closeSettingsSidebar())
    }

    return (
        <>
            <CSidebar position='fixed' className="border-start" placement="end" visible={visible}>
                <CSidebarHeader className="border-bottom">
                    Settings
                    <CIcon icon={cilChevronRight} onClick={close} className="float-end" color="light"></CIcon>
                </CSidebarHeader>
                <CSidebarNav>
                    <SettingsRefresher />
                    <SettingsDarkMode />
                    <SettingsTeams />
                </CSidebarNav>
            </CSidebar>
        </>
    )
}

export default SettingsSidebar
