import React from 'react'
import { CCard, CCardBody, CCardTitle } from '@coreui/react'
import TeamProgress, { TeamProgressData } from './TeamProgress'
import Loader from './Loader'

const TeamProgressDashboard: React.FC<{ title:string, progresses: TeamProgressData[] }> = ({ title, progresses }) => (
    <>
        <CCard>
            <CCardBody>
                <CCardTitle>{title}</CCardTitle>
                <Loader>
                    {progresses.map((progress) => (
                        <TeamProgress key={progress.team.teamId} progress={progress}/>
                    ))}
                </Loader>
            </CCardBody>
        </CCard>
    </>
)

export default TeamProgressDashboard
