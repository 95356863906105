import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router'
import Issue from '../../models/jira/Issue'
import jiraClient from '../../services/JiraClient'
import { resetCredentials } from '../credentials/Action'
import Refresher, { IssueQuery } from '../../services/Refresher'

export const updateIssues = createAction<Issue[]>('issues/update')

export const searchIssues = createAsyncThunk('issues/fetch', async (param: string | { query:string, autoRefresh?:boolean, clear?:boolean }, api) => {
    const searchQuery : string = typeof param === 'string' ? param : param.query
    const autoRefresh : boolean = typeof param !== 'string' && (param.autoRefresh || false)
    const clear: boolean = typeof param !== 'string' && (param.clear || false)

    Refresher.registerQuery({ query: searchQuery, autoRefresh })

    if (clear) {
        api.dispatch(updateIssues([]))
    }

    let issues : Issue[]

    try {
        issues = await jiraClient.searchIssues(searchQuery)
    } catch (e) {
        console.error(e)
        api.dispatch(resetCredentials())
        const navigate = useNavigate()
        navigate('/login')
        return []
    }

    if (autoRefresh) {
        Refresher.registerAutoRefresh((lastQuery: IssueQuery) => {
            api.dispatch(searchIssues(lastQuery)).catch(() => {
                console.error('Failed to refresh issues')
            })
        })
    }

    return issues
})
