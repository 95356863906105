import React from 'react'
import { CProgress, CProgressBar } from '@coreui/react'
import Team from '../../models/Team'

export interface TeamProgressData {
    team: Team,
    progress: number,
    total: number,
    color?: string,
}

const TeamProgress: React.FC<{ progress: TeamProgressData }> = ({ progress }) => {
    const getColor = (value:number):string => {
        if (value <= 25)
            return 'danger'
        if (value <= 50)
            return 'warning'
        if (value <= 75)
            return 'info'
        return 'success'
    }

    const value = progress.total > 0 ? Math.round((progress.progress / progress.total) * 100) : 0

    return (
        <>
            <>{progress.team.name}</>
            <CProgress height={20} color={progress.color ?? getColor(value)} value={value}>
                <CProgressBar className="overflow-visible text-dark px-1">{`${value}% (${progress.progress}/${progress.total})`}</CProgressBar>
            </CProgress>
        </>
    )
}

export default TeamProgress
