import React, { ReactNode, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import MainLayout from '../layouts/MainLayout'
import { getTeams } from '../stores/teams/Selector'
import { getCurrentProductIncrement } from '../stores/productIncrements/Selector'
import State from '../stores/State'
import { searchIssues } from '../stores/issues/Action'
import TeamTabs from '../components/Shared/TeamTabs'
import Team from '../models/Team'
import CurrentPIBacklog from '../components/HomePage/CurrentPIBacklog'
import CurrentPIDashboard from '../components/HomePage/CurrentPIDashboard'

const HomePage : React.FC = () => {
    const teams = useSelector(getTeams)
    const currentProductIncrement = useSelector(getCurrentProductIncrement)
    const dispatch = useDispatch<ThunkDispatch<State, unknown, UnknownAction>>()

    useEffect(() => {
        dispatch(searchIssues({
            // eslint-disable-next-line max-len
            query: `issueType = Feature AND PI in('${currentProductIncrement.tag}') AND Team in(${teams.map((team) => team.teamId).join(',')}) ORDER BY status ASC, Rank ASC`,
            autoRefresh: true,
            clear: true,
        })).catch(() => {
            console.error('Failed to load issues')
        })
    }, [])

    return (
        <>
            <MainLayout>
                <CurrentPIDashboard />
                <h1>Backlogs</h1>
                <TeamTabs content={(team:Team):ReactNode => (
                    <CurrentPIBacklog team={team}></CurrentPIBacklog>
                )}></TeamTabs>
            </MainLayout>
        </>
    )
}

export default HomePage
