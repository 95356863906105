import React from 'react'
import { CCol, CRow } from '@coreui/react'
import { CIcon } from '@coreui/icons-react'
import { cilSad } from '@coreui/icons'
import MainLayout from '../../layouts/MainLayout'

const IssueNotFound : React.FC = () => (
    <>
        <MainLayout>
            <CRow>
                <CCol>
                    <CIcon icon={cilSad}></CIcon> Issue not found
                </CCol>
            </CRow>
        </MainLayout>
    </>
)

export default IssueNotFound
