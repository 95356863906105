import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { CCard, CCardBody, CCardTitle, CNav, CNavItem, CNavLink, CCardHeader, CButton, CButtonGroup } from '@coreui/react'
import Utils from '../../utils/Utils'
import AccountTable from './AccountTable'
import AccountChart from './AccountChart'
import { getIssuesByCommitment, getIssuesByReadiness } from '../../stores/issues/Selector'
import Loader from '../Shared/Loader'
import { getBillableTeams } from '../../stores/teams/Selector'
import Issue from '../../models/jira/Issue'
import { CustomField } from '../../models/jira/CustomField'

type GroupBy = 'commitment' | 'readiness'
type Tabs = 'chart' | 'details'

const NextPIChart: React.FC<unknown> = () => {
    const [groupBy, setGroupBy] = useState<GroupBy>('commitment')
    const [activeTab, setActiveTab] = useState<Tabs>('chart')

    const billableTeams = useSelector(getBillableTeams)
    const billableTeamLabels = billableTeams.map((team) => team.teamId)

    const onlyBillableTeam = (issues: Issue[]):Issue[] => (
        issues.filter((issue):boolean => {
            if (!issue.fields[CustomField.TEAM]?.id)
                return false

            return billableTeamLabels.includes(issue.fields[CustomField.TEAM].id)
        })
    )

    const commitmentBySubAccount = useSelector(createSelector([getIssuesByCommitment, getBillableTeams], (issuesByCommitment) => (
        Utils.getEffortByAccount(
            onlyBillableTeam(issuesByCommitment.committed),
            onlyBillableTeam(issuesByCommitment.notCommitted),
        )
    )))

    const commitmentByAccount = useSelector(createSelector([getIssuesByCommitment, getBillableTeams], (issuesByCommitment) => (
        Utils.getEffortByAccount(
            onlyBillableTeam(issuesByCommitment.committed),
            onlyBillableTeam(issuesByCommitment.notCommitted),
            (account:string):string => (
                Utils.extractMainAccount(account)
            ),
        )
    )))

    const readinessBySubAccount = useSelector(createSelector([getIssuesByReadiness, getBillableTeams], (issuesByReadiness) => (
        Utils.getEffortByAccount(
            onlyBillableTeam(issuesByReadiness.ready),
            onlyBillableTeam(issuesByReadiness.notReady),
        )
    )))

    const readinessByAccount = useSelector(createSelector([getIssuesByReadiness, getBillableTeams], (issuesByReadiness) => (
        Utils.getEffortByAccount(
            onlyBillableTeam(issuesByReadiness.ready),
            onlyBillableTeam(issuesByReadiness.notReady),
            (account:string):string => (
                Utils.extractMainAccount(account)
            ),
        )
    )))

    return (
        <>
            <CCard>
                <CCardHeader>
                    <CButtonGroup className='float-end' size="sm" role="group">
                        <CButton color="primary" variant="outline" onClick={():void => setGroupBy('commitment')} active={groupBy === 'commitment'}>By Commitment</CButton>
                        <CButton color="primary" variant="outline" onClick={():void => setGroupBy('readiness')} active={groupBy === 'readiness'}>By Readiness</CButton>
                    </CButtonGroup>

                    <CNav variant="tabs" className="card-header-tabs">
                        <CNavItem>
                            <CNavLink
                                href="#"
                                active={activeTab === 'chart'}
                                onClick={():void => setActiveTab('chart')}
                            >
                                Account Summary
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink
                                href="#"
                                active={activeTab === 'details'}
                                onClick={():void => setActiveTab('details')}
                            >
                                Account Details
                            </CNavLink>
                        </CNavItem>
                    </CNav>
                </CCardHeader>
                <CCardBody>
                    <Loader>
                        <CCardTitle>{groupBy === 'commitment' ? 'Commitment' : 'Readiness'} by Account</CCardTitle>
                        {activeTab === 'chart' ? (
                            <AccountChart
                                efforts={groupBy === 'commitment' ? commitmentByAccount : readinessByAccount}
                                goodLabel={groupBy === 'commitment' ? 'Committed' : 'Ready'}
                                goodColor={groupBy === 'commitment' ? '#28A745' : '#3399ff'}
                            />
                        ) : (
                            <AccountTable efforts={groupBy === 'commitment' ? commitmentBySubAccount : readinessBySubAccount} goodLabel={groupBy === 'commitment' ? 'Committed' : 'Ready'}/>
                        )}
                    </Loader>
                </CCardBody>
            </CCard>
        </>
    )
}

export default NextPIChart
