import { createSelector } from '@reduxjs/toolkit'
import Issue from '../../models/jira/Issue'
import { CustomField } from '../../models/jira/CustomField'
import { IssueState } from './Reducer'
import State from '../State'
import { getTeams } from '../teams/Selector'
import { GroupedMap, IssuesByCommitment, IssuesByReadiness } from '../../models/Types'
import Utils from '../../utils/Utils'

const groupByReadiness = (issues: Issue[]) : IssuesByReadiness => (
    Object.values(issues).reduce((acc, issue) => {
        const groupedIssues = { ...acc }
        if (Utils.isIssueReady(issue)) {
            groupedIssues.ready = [...acc.ready, issue]
        } else {
            groupedIssues.notReady = [...acc.notReady, issue]
        }

        return groupedIssues
    }, { notReady: [], ready: [] } as IssuesByReadiness)
)

export const getIssues = createSelector(
    (state:State) => state.issues,
    (issues:IssueState) => issues.items,
)

export const getIssuesByTeam = createSelector(
    [getIssues],
    (issues:Issue[]) => issues.reduce((acc, issue) => {
        if (!issue.fields[CustomField.TEAM]?.id)
            return acc

        return {
            ...acc,
            [issue.fields[CustomField.TEAM].id]: [...(acc[issue.fields[CustomField.TEAM].id] ?? []), issue],
        }
    }, {} as GroupedMap<Issue[]>),
)

export const getIssuesByTeamAndCommitment = createSelector(
    [getIssuesByTeam, getTeams],
    (issues:GroupedMap<Issue[]>, teams) => {
        const result: GroupedMap<IssuesByCommitment> = {}
        teams.forEach((team) => {
            const teamIssues = issues[team.teamId] ?? []

            const committed: Issue[] = []
            const notCommitted: Issue[] = []
            let cumulativeSize = 0

            teamIssues.forEach((issue) => {
                const size = Utils.normalizeSize(issue)
                cumulativeSize += size
                if (size > 0 && cumulativeSize <= team.velocity) {
                    committed.push(issue)
                } else {
                    notCommitted.push(issue)
                }
            })

            result[team.teamId] = {
                committed,
                notCommitted,
            }
        })
        return result
    },
)

export const getIssuesByCommitment = createSelector([getIssuesByTeamAndCommitment], (statusIssueByTeam) => (
    Object.values(statusIssueByTeam).reduce((acc, statusIssues) => (
        {
            committed: [...acc.committed, ...statusIssues.committed],
            notCommitted: [...acc.notCommitted, ...statusIssues.notCommitted],
        }
    ), { committed: [], notCommitted: [] } as IssuesByCommitment)
))

export const getIssuesByReadiness = createSelector([getIssues], (issues) => (
    groupByReadiness(issues)
))

export const getIssuesByAccount = createSelector(
    [getIssues],
    (issues:Issue[]) => issues.reduce((acc, issue) => {
        if (!issue.fields[CustomField.ACCOUNT]?.id)
            return acc

        return {
            ...acc,
            [issue.fields[CustomField.ACCOUNT].value]: [...(acc[issue.fields[CustomField.ACCOUNT].value] ?? []), issue],
        }
    }, {} as GroupedMap<Issue[]>),
)

export const getIssuesByAccountAndReadiness = createSelector(
    [getIssuesByAccount],
    (issuesByAccount:GroupedMap<Issue[]>) => (
        Object.keys(issuesByAccount).reduce((result, key) => (
            {
                ...result,
                [key]: groupByReadiness(issuesByAccount[key]),
            }
        ), {} as GroupedMap<IssuesByReadiness>)
    ),
)

export const getIssuesCount = createSelector(
    [getIssues],
    (issues:Issue[]) => issues.length,
)

export const getRefreshStatus = createSelector(
    (state:State) => state.issues,
    (issues:IssueState) => issues.refreshStatus,
)
