import React from 'react'
import { useSelector } from 'react-redux'
import { getIssuesByTeamAndCommitment } from '../../stores/issues/Selector'
import Utils from '../../utils/Utils'
import ProductIncrement from '../../models/ProductIncrement'
import { getTeams } from '../../stores/teams/Selector'
import TeamProgressDashboard from '../Shared/TeamProgressDashboard'

const NextPITeamProgress: React.FC<{ productIncrement: ProductIncrement }> = () => {
    const teams = useSelector(getTeams)
    const issues = useSelector(getIssuesByTeamAndCommitment)

    const progress = teams.map((team) => {
        const selectedIssues = issues[team.teamId] ? [...issues[team.teamId].committed] : []
        return {
            team,
            progress: selectedIssues.filter((issue) => Utils.isIssueReady(issue)).reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0),
            total: selectedIssues.reduce((acc, issue) => acc + Utils.normalizeSize(issue), 0),
        }
    })

    return (
        <TeamProgressDashboard title='Teams Ready Workloads' progresses={progress}></TeamProgressDashboard>
    )
}

export default NextPITeamProgress
