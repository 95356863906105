import React, { useState } from 'react'
import { CAlert, CAlertHeading, CButton, CCard, CCardBody, CCardText, CForm, CFormInput, CInputGroup, CInputGroupText } from '@coreui/react'

const CorrespondanceCard: React.FC<{ daysSpent:number, estimatedDoneSpent:number, estimatedProgressSpent:number }> = ({ daysSpent, estimatedDoneSpent, estimatedProgressSpent }) => {
    const [expectedBudget, setExpectedBudget] = useState<number>(0)
    const [correspondance, setCorrespondance] = useState<{ color: string, title: string, text:string }>()

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>): void => {
        e.preventDefault()

        if (estimatedDoneSpent === 0 || estimatedProgressSpent === 0 || expectedBudget === 0) {
            setCorrespondance({
                color: 'dark',
                title: 'Nobody knows',
                text: 'The issue is not started yet or your expected budget is invalid',
            })
            return
        }

        if (expectedBudget > estimatedDoneSpent) {
            setCorrespondance({
                color: 'success',
                title: 'Everything is fine !',
                text: 'The progression of this feature is good ! The velocity is higher than expected !',
            })
            return
        }

        if (expectedBudget > (estimatedDoneSpent + estimatedProgressSpent) / 2) {
            setCorrespondance({
                color: 'info',
                title: 'It looks good !',
                text: 'Some stories are still progressing but your budget looks safe !',
            })
            return
        }

        if (expectedBudget > estimatedProgressSpent) {
            setCorrespondance({
                color: 'warning',
                title: 'It looks good but...',
                text: 'You dont have flexibility with your budget regarding the velocity. Be careful !',
            })
            return
        }

        setCorrespondance({
            color: 'danger',
            title: 'Red alert !',
            text: 'This project may take more time than expected !',
        })
    }

    return (
        <CCard>
            <CCardBody className={correspondance ? `border-${correspondance.color}` : ''}>
                <CCardText className="text-center mt-4">
                    View the correspondance between your budget and the progress.<br />
                    Enter the expected budget, in days :
                </CCardText>
                <CForm onSubmit={handleSubmit}>
                    <CInputGroup className="mb-3">
                        <CFormInput aria-describedby="days-input" onBeforeInput={(e: React.CompositionEvent<HTMLInputElement>):void => {
                            if (!e.data.match(/\d/)) {
                                e.preventDefault()
                            }
                        } } onChange={(e):void => setExpectedBudget(parseInt(e.target.value, 10))}/>
                        <CInputGroupText id="days-input">days</CInputGroupText>
                        <CButton type="submit" color="primary" id="days-button">Should i be worried ?</CButton>
                    </CInputGroup>
                </CForm>
                {correspondance && (
                    <CAlert color={correspondance.color}>
                        <CAlertHeading as="h4">{correspondance.title}</CAlertHeading>
                        <p>{correspondance.text}</p>
                        <hr />
                        <p>Budget consumption : {expectedBudget === 0 ? 'NA' : `${Math.round((daysSpent / expectedBudget) * 100 * 10) / 10}%`}</p>
                    </CAlert>
                )}
            </CCardBody>
        </CCard>
    )
}

export default CorrespondanceCard
