import React, { useState } from 'react'
import { CButton, CCard, CCardBody, CCardTitle, CCol, CContainer, CForm, CFormInput, CInputGroup, CPopover } from '@coreui/react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { CIcon } from '@coreui/icons-react'
import { cilArrowRight, cilInfo } from '@coreui/icons'
import { updateCredentials } from '../stores/credentials/Action'
import Credentials from '../models/Credentials'

const LoginPage : React.FC = () => {
    const [credentials, setCredentials] = useState<Credentials>({ email: '', apiToken: '' })
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target
        setCredentials((prevData) => ({ ...prevData, [name]: value }))
    }

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>): void => {
        e.preventDefault()
        try {
            dispatch(updateCredentials(credentials))
            const queryParameters = new URLSearchParams(window.location.search)
            const redirectUrl = queryParameters.get('redirect')
            navigate(redirectUrl ?? '/')
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <CContainer fluid>
                <div className='row justify-content-center mt-sm-1 mt-sm-3 mt-lg-5'>
                    <CCol sm={12} md={6} lg={4} >
                        <CCard>
                            <CCardBody>
                                <CCardTitle>Log in</CCardTitle>
                                <CForm className="row g-3" onSubmit={handleSubmit}>
                                    <CCol sm={12}>
                                        <CFormInput placeholder="Email" aria-label="Email" label="Email" name="email" value={credentials.email} onChange={handleChange} required />
                                    </CCol>
                                    <CCol sm={12}>
                                        <CInputGroup>
                                            <CFormInput placeholder="Api Token" aria-label="Api Token" type="password" name="apiToken" value={credentials.apiToken} onChange={handleChange} required />
                                            <CPopover
                                                title="Generate your Jira Token"
                                                placement="right"
                                                content={(
                                                    <>
                                                        <p>
                                                            You can create an API Token by following the instructions in the
                                                            <a href="https://id.atlassian.com/manage-profile/security/api-tokens" target="_blank" rel="noopener noreferrer">
                                                                jira documentation.
                                                            </a>
                                                        </p>
                                                        <p>
                                                            You can save it in your favorite password manager (1password, Keepass, etc...)
                                                        </p>
                                                        <div className="d-grid gap-2">
                                                            <CButton
                                                                as="a"
                                                                href="https://id.atlassian.com/manage-profile/security/api-tokens"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                role="button"
                                                                color="light">
                                                                Go to Jira doc <CIcon icon={cilArrowRight}></CIcon>
                                                            </CButton>
                                                        </div>
                                                    </>

                                                )}
                                            >
                                                <CButton type="button" color="secondary" variant="outline">
                                                    <CIcon icon={cilInfo}></CIcon>
                                                </CButton>
                                            </CPopover>
                                        </CInputGroup>
                                    </CCol>
                                    <div className="col-sm-12 d-grid">
                                        <CButton color="primary" type="submit">Sign in</CButton>
                                    </div>
                                </CForm>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </div>
            </CContainer>
        </>
    )
}

export default LoginPage
