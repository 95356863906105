import React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { RouteObject } from 'react-router'
import HomePage from './routes/HomePage'
import LoginPage from './routes/LoginPage'
import ErrorPage from './routes/ErrorPage'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import IssuePage from './routes/IssuePage'
import NextPIPage from './routes/NextPIPage'

const wrapAuthenticatedRoute = (route: RouteObject): RouteObject => (
    {
        ...route,
        element: <AuthenticatedRoute redirectUrl={route.path}>{route.element}</AuthenticatedRoute>,
    }
)

const App:React.FC<unknown> = () => {
    const router = createBrowserRouter([
        wrapAuthenticatedRoute({
            id: 'home',
            path: '/',
            element: <HomePage />,
            errorElement: <ErrorPage />,
        }),
        wrapAuthenticatedRoute({
            id: 'next',
            path: 'next/:productIncrementTag',
            element: <NextPIPage />,
        }),
        wrapAuthenticatedRoute({
            id: 'issue-view',
            path: 'issues/:issueKey',
            element: <IssuePage/>,
        }),
        {
            id: 'login',
            path: '/login',
            element: <LoginPage />,
        },
    ])

    return (
        <>
            <RouterProvider router={router}></RouterProvider>
        </>
    )
}

export default App
